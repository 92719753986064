import { Video } from '@wunderdogsw/finbb-dms-theme'
import { Hero } from '@wunderdogsw/finbb-dms-theme'
import classNames from 'classnames'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import { HeroMediaFile } from '../../blocks/hero-block'
import { HeroProps } from './hero'
import HeroBanner from './hero-banner'

type HeroWithVideoProps = HeroProps & {
  file: HeroMediaFile
  gatsbyImageData?: IGatsbyImageData
  thumbnail: IGatsbyImageData
}

const HeroWithVideo: React.FC<HeroWithVideoProps> = ({
  bannerImage,
  bannerText,
  file,
  gatsbyImageData,
  landing,
  lead,
  primaryCallToAction,
  secondaryCallToAction,
  tertiaryCallToAction,
  title,
}) => {
  return (
    <div
      className={classNames('bg-gradient-to-b from-blue-900 to-blue-400', {
        'py-16 sm:py-24': landing,
        'pt-16 pb-8': !landing,
      })}
    >
      <Hero>
        <HeroBanner image={bannerImage} text={bannerText} />
        <Hero.Title landing={landing} inverse>
          {title}
        </Hero.Title>
        <Hero.Lead inverse>{lead}</Hero.Lead>
        <div className="w-full max-w-2xl mx-auto my-6 sm:my-12">
          <Video inverse video={file} thumbnail={gatsbyImageData} />
        </div>
        <Hero.CallToActionList
          landing={landing}
          primary={primaryCallToAction}
          secondary={secondaryCallToAction}
          tertiary={tertiaryCallToAction}
          inverse
        />
      </Hero>
    </div>
  )
}

export default HeroWithVideo
