import {
  AnnouncementBlockData,
  ArticleCollectionBlockData,
  BlocksRender,
  CtaBlockData,
  ExternalTarget,
  HighlightCollectionBlockData,
  HubSpotFormBlockData,
  ImageContentBlockData,
  InternalTarget,
  LogoCollectionBlockData,
  OperatorCollectionBlockData,
  PageRichText,
  SEO,
  ServiceCollectionBlockData,
  StepByStepBlockData,
  TextContentBlockData,
  TextContentCollectionBlockData,
  VideoBlockData,
} from '@wunderdogsw/finbb-dms-theme'
import { graphql } from 'gatsby'
import type { PageProps } from 'gatsby'
import React from 'react'
import * as blockComponents from '../blocks'
import type { HeroBlockData } from '../blocks/hero-block'
import Layout from '../components/layout/layout'
import HeroBlock from '../blocks/hero-block'
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import { OrganizationBlockData } from '@wunderdogsw/finbb-dms-theme/src/blocks/organization-block'
import { ContactCollectionBlockData } from '@wunderdogsw/finbb-dms-theme/src/blocks/contact-collection-block'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import useApplicationPrivacyPolicy from '../hooks/use-application-privacy-policy'

type ColumnedSectionBlockData = any

type BlockData =
  | AnnouncementBlockData
  | ArticleCollectionBlockData
  | ColumnedSectionBlockData
  | ContactCollectionBlockData
  | CtaBlockData
  | HighlightCollectionBlockData
  | HubSpotFormBlockData
  | ImageContentBlockData
  | LogoCollectionBlockData
  | OperatorCollectionBlockData
  | OrganizationBlockData
  | ServiceCollectionBlockData
  | StepByStepBlockData
  | TextContentBlockData
  | TextContentCollectionBlockData
  | VideoBlockData

type CallToActionTargetData = ExternalTarget | InternalTarget

type PageData = {
  contentfulPage: {
    afterBlocks: BlockData[]
    beforeBlocks: BlockData[]
    body: RenderRichTextData<ContentfulRichTextGatsbyReference>
    heroBlock: HeroBlockData
    metaDescription: {
      metaDescription: string
    }
    metaImage?: {
      file: {
        url: string
      }
      gatsbyImageData: IGatsbyImageData
    }
    metaTitle
    primaryCallToActionCaption: string
    primaryCallToActionTarget: CallToActionTargetData
    secondaryCallToActionCaption: string
    secondaryCallToActionTarget: CallToActionTargetData
  }
}

export type PageContextProps = {
  locale: string
}

const Page: React.FC<PageProps<PageData, PageContextProps>> = ({
  data,
  pageContext,
}) => {
  const applicationData = useApplicationPrivacyPolicy()

  const {
    contentfulPage: {
      afterBlocks,
      beforeBlocks,
      body,
      heroBlock,
      metaDescription,
      metaImage,
      metaTitle,
      automaticRedirect,
      redirectUrl,
      redirectTime,
    },
  } = data

  if (automaticRedirect) {
    // Set up the automatic redirect based on the URL and time from contentful
    setTimeout(() => (window.location = redirectUrl), redirectTime * 1000)
  }

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription.metaDescription}
        image={metaImage?.file?.url}
      />
      {heroBlock && <HeroBlock data={heroBlock} />}
      {beforeBlocks && (
        <BlocksRender
          applicationData={applicationData}
          components={blockComponents}
          locale={pageContext.locale}
        >
          {beforeBlocks}
        </BlocksRender>
      )}
      {body && (
        <div className="relative z-10 container mx-auto px-4 sm:px-8 py-8">
          <PageRichText>{body}</PageRichText>
        </div>
      )}
      {afterBlocks && (
        <BlocksRender
          applicationData={applicationData}
          components={blockComponents}
          locale={pageContext.locale}
        >
          {afterBlocks}
        </BlocksRender>
      )}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ($slug: String!, $locale: String!) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      afterBlocks {
        __typename
        ...Blocks
      }
      beforeBlocks {
        __typename
        ...Blocks
      }
      body {
        raw
        references {
          __typename
          ... on Node {
            ...RichTextReferences
          }
        }
      }
      heroBlock {
        ...HeroBlock
      }
      metaDescription {
        metaDescription
      }
      metaImage {
        file {
          url
        }
        gatsbyImageData(width: 2560)
      }
      metaTitle
      automaticRedirect
      redirectUrl
      redirectTime
    }
  }
`
