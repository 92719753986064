import {
  Hero as ThemeHero,
  HeroCallToActionData,
} from '@wunderdogsw/finbb-dms-theme'
import classNames from 'classnames'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import React from 'react'
import HeroBanner from './hero-banner'

export type HeroProps = {
  bannerText?: string
  bannerImage?: {
    file: {
      url: string
    }
    gatsbyImageData: IGatsbyImageData
  }
  landing: boolean
  lead: RenderRichTextData<ContentfulRichTextGatsbyReference>
  overlay: boolean
  primaryCallToAction: HeroCallToActionData
  secondaryCallToAction: HeroCallToActionData
  tertiaryCallToAction: HeroCallToActionData
  title: string
}

const Hero: React.FC<HeroProps> = ({
  bannerImage,
  bannerText,
  landing,
  lead,
  primaryCallToAction,
  secondaryCallToAction,
  tertiaryCallToAction,
  title,
}) => (
  <div
    className={classNames(
      landing ? 'py-16 sm:py-24' : 'pt-16 pb-8',
      'bg-gradient-to-b from-blue-900 to-blue-400'
    )}
  >
    <ThemeHero>
      <div
        className={classNames({
          'text-center': landing,
          'text-left': !landing,
        })}
      >
        <HeroBanner image={bannerImage} text={bannerText} />
        <ThemeHero.Title landing={landing} inverse>
          {title}
        </ThemeHero.Title>
        <ThemeHero.Lead align={landing ? 'center' : 'left'} inverse>
          {lead}
        </ThemeHero.Lead>
      </div>
      <ThemeHero.CallToActionList
        landing={landing}
        primary={primaryCallToAction}
        secondary={secondaryCallToAction}
        tertiary={tertiaryCallToAction}
        inverse
      />
    </ThemeHero>
  </div>
)

export default Hero
