import {
  ExternalTarget,
  InternalTarget,
  utils,
} from '@wunderdogsw/finbb-dms-theme'
import { graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import React from 'react'
import Hero from '../components/hero/hero'
import HeroWithBackgroundImage from '../components/hero/hero-with-background-image'
import HeroWithVideo from '../components/hero/hero-with-video'

export type HeroMediaFile = {
  contentType: string
  url: string
}

type HeroMedia = {
  file: HeroMediaFile
  gatsbyImageData: IGatsbyImageData
}

export type HeroBlockData = {
  backgroundMedia: HeroMedia | undefined
  backgroundOverlay?: boolean
  bannerImage?: {
    file: {
      url: string
    }
    gatsbyImageData: IGatsbyImageData
  }
  bannerText?: string
  id: string
  isFullScreen: boolean
  lead: RenderRichTextData<ContentfulRichTextGatsbyReference>
  primaryCallToActionCaption?: string
  primaryCallToActionTarget?: ExternalTarget | InternalTarget
  secondaryCallToActionCaption?: string
  secondaryCallToActionTarget?: ExternalTarget | InternalTarget
  tertiaryCallToActionCaption?: string
  tertiaryCallToActionTarget?: ExternalTarget | InternalTarget
  title: string
  videoThumbnail?: {
    gatsbyImageData: IGatsbyImageData
  }
}

type HeroBlockProps = {
  data: HeroBlockData
}

const HeroBlock: React.FC<HeroBlockProps> = ({ data }) => {
  const {
    backgroundMedia,
    backgroundOverlay,
    bannerImage,
    bannerText,
    isFullScreen,
    lead,
    primaryCallToActionCaption,
    primaryCallToActionTarget,
    secondaryCallToActionCaption,
    secondaryCallToActionTarget,
    tertiaryCallToActionCaption,
    tertiaryCallToActionTarget,
    title,
    videoThumbnail,
  } = data

  const primaryCallToAction = {
    caption: primaryCallToActionCaption,
    target: primaryCallToActionTarget,
  }
  const secondaryCallToAction = {
    caption: secondaryCallToActionCaption,
    target: secondaryCallToActionTarget,
  }
  const tertiaryCallToAction = {
    caption: tertiaryCallToActionCaption,
    target: tertiaryCallToActionTarget,
  }

  if (backgroundMedia === null) {
    return (
      <Hero
        title={title}
        lead={lead}
        bannerImage={bannerImage}
        bannerText={bannerText}
        primaryCallToAction={primaryCallToAction}
        secondaryCallToAction={secondaryCallToAction}
        tertiaryCallToAction={tertiaryCallToAction}
        landing={isFullScreen}
        overlay={backgroundOverlay}
      />
    )
  }

  return utils.isVideo(data.backgroundMedia.file.contentType) ? (
    <HeroWithVideo
      title={title}
      lead={lead}
      bannerImage={bannerImage}
      bannerText={bannerText}
      file={backgroundMedia.file}
      gatsbyImageData={videoThumbnail.gatsbyImageData}
      overlay={false}
      primaryCallToAction={primaryCallToAction}
      secondaryCallToAction={secondaryCallToAction}
      tertiaryCallToAction={tertiaryCallToAction}
      landing={isFullScreen}
    />
  ) : (
    <HeroWithBackgroundImage
      title={title}
      lead={lead}
      bannerImage={bannerImage}
      bannerText={bannerText}
      gatsbyImageData={backgroundMedia.gatsbyImageData}
      primaryCallToAction={primaryCallToAction}
      secondaryCallToAction={secondaryCallToAction}
      tertiaryCallToAction={tertiaryCallToAction}
      landing={isFullScreen}
      overlay={backgroundOverlay}
    />
  )
}

export default HeroBlock

export const query = graphql`
  fragment HeroBlock on ContentfulHeroBlock {
    backgroundMedia {
      gatsbyImageData(width: 2560)
      file {
        contentType
        url
      }
    }
    backgroundOverlay
    bannerImage {
      file {
        url
      }
      gatsbyImageData(width: 640)
    }
    bannerText
    id
    isFullScreen
    lead {
      raw
      references {
        __typename
        ... on Node {
          ... on ContentfulSuperscriptInline {
            ...SuperscriptInline
          }
        }
      }
    }
    primaryCallToActionCaption
    primaryCallToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          ...ArticlePage
        }
        ... on ContentfulLink {
          id
          url
        }
        ... on ContentfulPage {
          ...Page
        }
      }
    }
    secondaryCallToActionCaption
    secondaryCallToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          ...ArticlePage
        }
        ... on ContentfulLink {
          id
          text
          url
        }
        ... on ContentfulPage {
          ...Page
        }
      }
    }
    tertiaryCallToActionCaption
    tertiaryCallToActionTarget {
      __typename
      ... on Node {
        ... on ContentfulArticlePage {
          ...ArticlePage
        }
        ... on ContentfulLink {
          id
          text
          url
        }
        ... on ContentfulPage {
          ...Page
        }
      }
    }
    title
    videoThumbnail {
      gatsbyImageData(width: 2560)
    }
  }
`
