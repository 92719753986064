import { BackgroundImage, Hero } from '@wunderdogsw/finbb-dms-theme'
import classNames from 'classnames'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import { HeroProps } from './hero'
import HeroBanner from './hero-banner'

type HeroWithBackgroundImageProps = HeroProps & {
  gatsbyImageData: IGatsbyImageData
}

const HeroWithBackgroundImage: React.FC<HeroWithBackgroundImageProps> = ({
  bannerImage,
  bannerText,
  gatsbyImageData,
  landing,
  lead,
  overlay,
  primaryCallToAction,
  secondaryCallToAction,
  tertiaryCallToAction,
  title,
}) => {
  return (
    <div className="relative">
      <BackgroundImage gatsbyImageData={gatsbyImageData} overlay={overlay} />
      <HeroBanner image={bannerImage} text={bannerText} />
      <div
        className={classNames('relative', {
          'py-16 sm:py-24': landing,
          'pt-16 pb-8': !landing,
        })}
      >
        <Hero>
          <div
            className={classNames({
              'text-center': landing,
              'text-left': !landing,
            })}
          >
            <Hero.Title landing={landing} inverse shadow>
              {title}
            </Hero.Title>
            <Hero.Lead inverse shadow>
              {lead}
            </Hero.Lead>
          </div>
          <Hero.CallToActionList
            landing={landing}
            primary={primaryCallToAction}
            secondary={secondaryCallToAction}
            tertiary={tertiaryCallToAction}
            inverse
          />
        </Hero>
      </div>
    </div>
  )
}

export default HeroWithBackgroundImage
